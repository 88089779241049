import React from 'react';
import { useRouter } from 'next/router';
import { Box, Drawer as MuiDrawer, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import ErrorBoundary from 'sensortower-components/src/base-components/ErrorBoundary';
import { LinkProps } from 'sensortower-components/src/base-components/Link';
import ContentModule from 'sensortower-components/src/base-components/ContentModule';
import { NavigationItemLinkProps } from '../NavigationItemLink';

export interface HamburgerMenuProps {
  id?: string;
  __typename?: string;
  items: Array<NavigationItemLinkProps | LinkProps>;
}

export const HamburgerMenu = ({ items }: HamburgerMenuProps) => {
  const [open, setOpen] = React.useState(false);
  const router = useRouter();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  React.useEffect(() => {
    setOpen(false);
  }, [router?.asPath]);

  return (
    <ErrorBoundary>
      <>
        <Root data-testid="HamburgerMenu">
          <Menu>
            <input checked={open} type="checkbox" onChange={handleToggle} data-testid="HamburgerMenu-menu" />
            <span></span>
            <span></span>
            <span></span>
          </Menu>
        </Root>
        <Drawer
          anchor="top"
          sx={{ zIndex: 900, display: { xs: 'block', lg: 'none' } }}
          elevation={0}
          open={open}
          onClose={handleToggle}
          data-testid="HamburgerMenu-drawer">
          <Box px={2} pt={12} pb={2}>
            <Grid container spacing={0} sx={{ alignItems: 'center' }}>
              {items?.map((item) => (
                <Grid item xs={12} key={item.id}>
                  <ContentModule {...item} color="secondary" />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Drawer>
      </>
    </ErrorBoundary>
  );
};

export default HamburgerMenu;

const Root = styled(Box, {
  name: 'HamburgerMenu',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => ({
    ...(styles.root as any)
  })
})<{ variant?: string }>(() => ({
  position: 'relative'
}));

const Menu = styled(Box, {
  name: 'HamburgerMenu',
  slot: 'Menu',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => ({
    ...(styles.root as any)
  })
})<{ variant?: string }>(({ theme }) => ({
  'display': 'block',
  'position': 'relative',
  'zIndex': 1,
  'WebkitUserSelect': 'none',
  'userSelect': 'none',
  '& input': {
    display: 'block',
    width: 25,
    height: 28,
    position: 'absolute',
    top: -7,
    left: -5,
    cursor: 'pointer',
    opacity: 0,
    zIndex: 2,
    WebkitTouchCallout: 'none'
  },
  '& span': {
    display: 'block',
    width: 25,
    height: 3,
    marginBottom: 5,
    position: 'relative',
    background: theme.palette.action.active,
    borderRadius: 3,
    zIndex: 1,
    transformOrigin: '4px 0px',
    transition:
      'transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease '
  },
  '& input:checked ~ span:nth-last-of-type(3)': {
    opacity: 0
  },
  '& input:checked ~ span:nth-last-of-type(1)': {
    opacity: 0
  }
}));

const Drawer = styled(MuiDrawer, {
  name: 'HamburgerMenu',
  slot: 'Drawer',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => ({
    ...(styles.root as any)
  })
})<{ variant?: string }>(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.action.active
  },
  '& .MuiGrid-container': {
    '& .MuiGrid-root:nth-last-of-type(2)': {
      '& .MuiDivider-root:last-of-type': {
        display: 'none'
      }
    },
    '& .MuiGrid-root:nth-last-of-type(1)': {
      '& .MuiButton-root': {
        marginTop: theme.spacing(4)
      }
    }
  }
}));
